import axios from "axios";

export default function FetchData(
  options,
  setData,
  setDataLoading,
  setServerMsg,
  success
) {
  //   console.log(" fireee", options);
  axios(options)
    .then(function (response) {
      // console.log(response.data);
      // console.log(response.data.data);
      setData(response.data.data);
      setServerMsg(null);
      setDataLoading(false);
    })

    .catch(function (error) {
      console.log(error.response);
    });
}
