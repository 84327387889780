import "./App.css";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Switch, HashRouter, Route } from "react-router-dom";

import Header from "./components/header/Header";
import Home from "./pages/Home";
import LoginPage from "./pages/login/LoginPage";

// redux
import { store } from "./redux/store";
import { Provider } from "react-redux";
import Layout from "./components/Layout";
import User from "./pages/User";
import EditUser from "./pages/EditUser";
import CreateUser from "./pages/CreateUser";
import CreateEmployee from "./pages/CreateEmployee";
import CreatePartition from "./pages/CreatePartition";
import EditPartition from "./pages/EditPartition";

import Partition from "./pages/Partition";
import CreateCategory from "./pages/CreateCategory";
import Category from "./pages/Category";
import EditCategory from "./pages/EditCategory";
import Employees from "./pages/Employees";
import Blogs from "./pages/Blogs";
import CreateBlog from "./pages/CreateBlog";
import EditBlog from "./pages/EditBlog";
import EditEmployee from "./pages/EditEmployee";
import Content from "./pages/Content";

// import PrivateRoute from "./routes/PrivateRoute";
import Tag from "./pages/Tag";
import CreateTag from "./pages/CreateTag";
import EditTag from "./pages/EditTag";
import CreateSlider from "./pages/CreateSlider";
import Slider from "./pages/Slider";
import EditPageHeader from "./pages/EditPageHeader";
import PageHeader from "./pages/PageHeader";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
function App() {
  require("dotenv").config();
  let persistor = persistStore(store);
  return (
    <Provider store={store}>
      <HashRouter basename="/">
        <PersistGate persistor={persistor}>
          <div className="App light-theme  dark-theme" dir="ltr">
            {console.log("env:", process.env.REACT_APP_API_BASEURL)}

            <Header />
            <Switch>
              {/* <PrivateRoute component={<Home />} path="/home" exact /> */}
              <Route
                exact
                path="/"
                component={(props) => <LoginPage {...props} />}
              />
              <Layout>
                {/* <Route
                exact
                path="/Home"
                component={(props) => <Home {...props} />}
              /> */}
                <Route
                  exact
                  path="/Users"
                  component={(props) => <User {...props} />}
                />
                <Route
                  exact
                  path="/EditUser/:id"
                  component={(props) => <EditUser {...props} />}
                />

                <Route
                  exact
                  path="/CreateUser"
                  component={(props) => <CreateUser {...props} />}
                />

                <Route
                  exact
                  path="/Partition"
                  component={(props) => <Partition {...props} />}
                />

                <Route
                  exact
                  path="/CreatePartition"
                  component={(props) => <CreatePartition {...props} />}
                />

                <Route
                  exact
                  path="/EditPartition/:id"
                  component={(props) => <EditPartition {...props} />}
                />

                <Route
                  exact
                  path="/CreateCategory"
                  component={(props) => <CreateCategory {...props} />}
                />

                <Route
                  exact
                  path="/AllCategory"
                  component={(props) => <Category {...props} />}
                />

                <Route
                  exact
                  path="/EditCategory/:id"
                  component={(props) => <EditCategory {...props} />}
                />

                <Route
                  exact
                  path="/CreateEmployee"
                  component={(props) => <CreateEmployee {...props} />}
                />
                <Route
                  exact
                  path="/EditEmployee/:id"
                  component={(props) => <EditEmployee {...props} />}
                />

                <Route
                  exact
                  path="/AllEmployees"
                  component={(props) => <Employees {...props} />}
                />

                <Route
                  exact
                  path="/CreateBlog"
                  component={(props) => <CreateBlog {...props} />}
                />

                <Route
                  exact
                  path="/Blogs"
                  component={(props) => <Blogs {...props} />}
                />

                <Route
                  exact
                  path="/Content"
                  component={(props) => <Content {...props} />}
                />

                <Route
                  exact
                  path="/EditBlog/:id"
                  component={(props) => <EditBlog {...props} />}
                />

                <Route
                  exact
                  path="/Tag"
                  component={(props) => <Tag {...props} />}
                />
                <Route
                  exact
                  path="/CreateTag"
                  component={(props) => <CreateTag {...props} />}
                />
                <Route
                  exact
                  path="/EditTag/:id"
                  component={(props) => <EditTag {...props} />}
                />

                <Route
                  exact
                  path="/Slider"
                  component={(props) => <Slider {...props} />}
                />
                <Route
                  exact
                  path="/CreateSlider"
                  component={(props) => <CreateSlider {...props} />}
                />

                <Route
                  exact
                  path="/PageHeader"
                  component={(props) => <PageHeader {...props} />}
                />
                <Route
                  exact
                  path="/EditPageHeader/:id"
                  component={(props) => <EditPageHeader {...props} />}
                />
              </Layout>

              {/* <PublicRoute restricted={true} component={Login} path="/" exact />

            // <PrivateRoute component={UsersList} path="/Users" exact /> */}
            </Switch>
          </div>
        </PersistGate>
      </HashRouter>
    </Provider>
  );
}

export default App;
