import { NavLink } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="menu-links" id="menu">
      <ul>
        <li className="nav-item ">
          {/* <NavLink
            to="/Blogs"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-home" aria-hidden="true"></i>
            <span>Blogs</span>
          </NavLink> */}
        </li>
        <li className="nav-item">
          <NavLink
            to="/Users"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span>Users</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/CreateUser"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span>Create User</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/CreatePartition"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span>Create Partition</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/Partition"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span> Partition</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/CreateCategory"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span> Create Category</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/AllCategory"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span>Categories</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/CreateEmployee"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span>Create Employee</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/AllEmployees"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span> Employees</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/CreateBlog"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span> Create Blog</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            to="/Blogs"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span> Blogs</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/Content"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span> Content</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/Tag"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span> Tag</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/CreateTag"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span> Create Tag</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/Slider"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span> Slider</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/CreateSlider"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span> Create Slider</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/PageHeader"
            className={(isActive) =>
              "d-flex align-items-center nav-link " +
              (!isActive ? " " : "active")
            }
          >
            <i className="fa fa-briefcase" aria-hidden="true"></i>
            <span> Page Header</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
