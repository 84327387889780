import { useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import { RemoveUser } from "../../redux/User";
import { message } from "antd";
import axios from "axios";

export default function Header() {
  let location = useLocation();
  let dispatch = useDispatch();
  let history = useHistory();

  const user = useSelector((state) => state.user.data);

  const success = () => {
    message.success("You Are Successfully Logged out");
  };

  const logout = () => {
    const options = {
      method: "post",
      url: `${process.env.REACT_APP_API_BASEURL}/api/admin/logout`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios(options)
      .then(function (response) {
        console.log(response.data);
        dispatch(RemoveUser());
        success();
        history.push("/");
      })

      .catch(function (error) {
        console.log(error.response.data);
      });
  };

  const drawer = () => {
    console.log("test");
    console.log(document.getElementById("menu"));
    document.getElementById("menu").classList.toggle("left");
  };

  useEffect(() => {
    if (location.pathname !== "/") {
      document
        .getElementById("hamburger")
        .addEventListener("click", () => drawer());
    }

    console.log("user:", user);
  });

  if (location.pathname !== "/")
    return (
      <>
        <header>
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="header-inner d-flex justify-content-between align-items-center">
                <a href="/" className="navbar-brand flex-shrink-0">
                  {/* <img
                    src="https://yudiz.com/codepen/nft-store/logo-icon.svg"
                    alt="logo"
                    className="img-fluid"
                  /> */}

                  <h1> Logo</h1>
                </a>
                <div className="header-content d-flex align-items-center justify-content-end">
                  {/* <form className="d-flex justify-content-end align-items-center">
                    <label className="switch flex-shrink-0 mb-0">
                      <input id="checkbox" type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </form> */}
                  {/* <a href="/" className="profile">
                    <img
                      src="https://yudiz.com/codepen/nft-store/user-pic1.svg"
                      alt="user"
                    />
                    Johny B
                  </a> */}

                  <DropdownButton
                    id="dropdown-basic-button"
                    title={
                      <>
                        <img
                          src="https://yudiz.com/codepen/nft-store/user-pic1.svg"
                          alt="user"
                        />
                        {user !== [] ? user.first_name : "test"}
                      </>
                    }
                  >
                    <Dropdown.Item onClick={() => logout()}>
                      logout
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
              <button id="hamburger" className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </button>
            </nav>
          </div>
        </header>
      </>
    );
  else return null;
}
