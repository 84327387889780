import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function ManagerSection({ section }) {
  return (
    <section className="ChairmanSection">
      <Container>
        <Row>
          <Col md={6} sm={12}>
            <img src={section.photo} alt="" className="img-fluid" />
          </Col>
          <Col md={6} sm={12} className=" pt-3 pb-4 px-4">
            <div>
              <h2>{section.key}</h2>

              <h2>{section.title}</h2>
              <h3>{section.sub_title}</h3>
            </div>
            <p>{section.short_description}</p>

            <p>{section.description}</p>
            <div className="d-flex align-items-center justify-content-center w-100">
              <Link to={`/EditPartition/${section.id}`}> Edit</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
